import { Controller } from "@hotwired/stimulus"
import * as FilePond from "filepond"
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {
  connect() {
    const uploadUrl = document.querySelector('meta[name="direct-upload-url"]').content
    const form = this.element.closest("form")
    const input = this.element.querySelector("input")
    const inputName = input.getAttribute("name")


    this.pond = FilePond.create(input, {
      storeAsFile: true,
      allowMultiple: true,
      // server: {
      //   process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
      //     const uploader = new DirectUpload(file, uploadUrl, {
      //       directUploadWillStoreFileWithXHR: (request) => {
      //         request.upload.addEventListener("progress", (event) => {
      //           progress(event.lengthComputable, event.loaded, event.total)
      //         })
      //       }
      //     })

      //     uploader.create((errorResponse, blob) => {
      //       if (errorResponse) {
      //         error(`Upload error: ${errorResponse}`)
      //       } else {
      //         load(blob.signed_id)
      //         this.createHiddenInput(form, inputName, blob.signed_id)
      //       }
      //     })

      //     return {
      //       abort: () => {
      //         uploader.abort()
      //         abort()
      //       }
      //     }
      //   },
      //   headers: {
      //     'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      //   }
      // },
      onremovefile: (error, file) => {
        if (file.serverId) {
          const hiddenField = form.querySelector(`input[name="${inputName}[]"][value="${file.serverId}"]`)
          if (hiddenField) hiddenField.remove()
        }
      },
      onaddfile: (error, file) => {
        window.dispatchEvent(new Event('resize'))
      },
      oninit: () => {
        // setTimeout(() => {
          this.element.classList.remove("animate-pulse")
        // }, 100)
      }
    })


  }

  createHiddenInput(form, name, value) {
    const input = document.createElement('input')
    input.type = 'hidden'
    input.name = `${name}[]`
    input.value = value
    form.appendChild(input)
  }
}
